@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

@tailwind base;

@tailwind components;

@tailwind utilities;

@font-face {
  font-family: Inter;
  src: url('assets/Inter.otf') format('opentype');
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Italic.otf') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Thin.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-ThinItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-SemiBold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-ExtraBold.otf') format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-ExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-Black.otf') format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: Inter;
  src: url('assets/Inter-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

html {
  font-family: Inter;
  font-feature-settings: 'tnum' off, 'lnum' on, 'zero' off, 'ss01' on;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
}

@media (prefers-color-scheme: light) {
  html {
    color: #12141d;
  }
}

@media (prefers-color-scheme: dark) {
  html {
    color: white;
  }
}

.card-with-shadow {
  @apply bg-white shadow dark:bg-gray-900;
}

.tooltip-overrides {
  max-width: 300px !important;
  border-radius: 6px !important;
  text-align: left;
  @apply p-2 font-normal text-black dark:text-white text-sm bg-white shadow dark:bg-gray-900 border border-gray-600 dark:border-slate-800 !important;
}

@layer utilities {
  .card-with-shadow-white-no-radius {
    @apply shadow bg-white dark:bg-zinc-900;
  }
}

.card-no-radius {
  @apply bg-white dark:bg-zinc-900;
}

.has-tooltip {
  @apply underline decoration-dotted cursor-help;
}

.alternating-row-even {
  @apply even:bg-gray-100 dark:even:bg-gray-900;
}

.alternating-row-odd {
  @apply odd:bg-gray-100 dark:odd:bg-gray-900;
}

.mat-icon {
  @apply align-middle;
}

.cdk-drag-preview {
  @apply shadow transition-shadow duration-150 !important;
}

.cdk-drop-list-dragging,
.cdk-drop-list-receiving {
  @apply cursor-grabbing min-h-5 transition-colors duration-100 bg-gray-100 dark:bg-gray-900 !important;
}

.cdk-drop-list .drop-area {
  @apply hidden;
}

.cdk-drop-list-dragging .hide, .cdk-drop-list-receiving .hide {
  @apply hidden !important;
}

.mat-dialog-actions {
  @apply gap-x-2 justify-between;
}

.no-padding-dialog .mat-dialog-container {
  @apply p-0 overflow-hidden lg:max-h-[400px] bg-white dark:bg-zinc-900/75 backdrop-blur border border-gray-600 dark:border-slate-800 !important;
}
